import axios from "axios";
// @ts-ignore
import {apiPath, hostname, protocol} from '../constant/constants.ts';
// import {useAuth} from "../../contexts/AuthContext";

export async function checkValidUser(username: string, password: string){
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    const response = await axios.post(`${protocol}://${hostname}/${apiPath}/login`, formData);
    return response.data;
}

// export function getUsername(){
//     const { contextUsername } = useAuth();
//     return contextUsername
// }

export async function reloadConversation(username: any){
    const formData = new FormData();
    formData.append('username', username);
    const response = await axios.post(`${protocol}://${hostname}/${apiPath}/reload`, formData);
    return response.data
}

export async function finishInterview(username: any){
    const formData = new FormData()
    formData.append("username", username)
    const response = await axios.post(`${protocol}://${hostname}/${apiPath}/finish`, formData);
    return response.status
}