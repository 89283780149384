import React, { useState, useRef } from 'react';
import './AudioRecorder.css'; // Import the CSS file for styling

function AudioRecorder({ onAudioSubmit }) {
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioUrl(audioUrl);
      onAudioSubmit(audioBlob);
      audioChunksRef.current = [];
    };

    mediaRecorder.start();
    setRecording(true);
  };

  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  };

  return (
    <div className="audio-recorder">
      {!recording ? (
        <button className="record-button start" onClick={handleStartRecording}>
          <i className="fas fa-microphone"></i>
        </button>
      ) : (
        <button className="record-button stop" onClick={handleStopRecording}>
          <i className="fas fa-microphone-slash"></i>
        </button>
      )}
    </div>
  );
}

export default AudioRecorder;