// src/components/MainApp.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import AudioRecorder from './AudioRecorder';
import './MainApp.css';
import {apiPath, hostname, protocol} from "./constant/constants.ts";
import {finishInterview, reloadConversation} from "./logic/backendCalls.ts";
import {useAuth} from "../contexts/AuthContext";

function MainApp() {
  const { contextUsername } = useAuth();
  const [messages, setMessages] = useState([
    { 
      role: 'assistant', 
      content: `<strong>Charlie (Interviewer)</strong>: Welcome to your interview! My name is Charlie, and I will be your interviewer today. To begin, please follow these steps:<br />
      1. Press the <span style="color: green; font-weight: bold;">GREEN mic button</span> to start talking.<br />
      2. When you finish talking, press the <span style="color: red; font-weight: bold;">RED mic button</span> to stop talking.<br />
      Please repeat this process for every question I ask. <br />
      <br />
      <strong>Let's start with your name!</strong>
      ` }
  ]);
  const [loading, setLoading] = useState(false);
  const chatWindowRef = useRef(null); // Create a reference for the chat window
  const hasRebuilt = useRef(false);

  useEffect(() => {
    console.log("%c username" + contextUsername, "color:red")
    if (!hasRebuilt.current)
    {
      console.log("start rebuild")
      hasRebuilt.current=true
      reloadConversation(contextUsername).then(data => {
        console.log(data)
        if (data["user_exist"] === true) {
          for (const iter in data["conversation"]) {
            console.log("%c converstion liter", "color: red")
            console.log(data["conversation"][iter])
            if (data["conversation"][iter]["type"] === "user")
              addMessage(data["conversation"][iter]["type"], `<strong>User</strong>: ${data["conversation"][iter]["content"]}`)
            else
              addMessage(data["conversation"][iter]["type"], `<strong>Charlie (Interviewer)</strong>: ${data["conversation"][iter]["content"]}`)
          }
        }
      })
    }
  }, []);

  const addMessage = (role, content) => {
    setMessages((prevMessages) => [...prevMessages, { role, content }]);
  };

  const handleAudioSubmit = async (file) => {
    const fileName = file.name || 'audio.mp3'; // Default name for the file
    console.log('File name:', fileName);
    console.log('File:', file);
  
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('username', contextUsername)

    setLoading(true);
    try {
      const response = await axios.post(`${protocol}://${hostname}/${apiPath}/talk`, formData, {
        responseType: 'arraybuffer',
      });
      console.log("%c talk response", "color: blue")
      console.log(response)
      const audioBlob = new Blob([response.data], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);

      // Fetch the last message from the server
      const formDataGetMessage = new FormData();
      formDataGetMessage.append('username', contextUsername)
      const lastMessagesResponse = await axios.post(`${protocol}://${hostname}/${apiPath}/last_two_messages`, formDataGetMessage);
      const { last_message, second_last_message } = lastMessagesResponse.data;
      
      // Add the last two messages with specific labels
      if (second_last_message) {
        addMessage('user', `<strong>User</strong>: ${second_last_message.content}`);
      }
      if (last_message) {
        addMessage('assistant', `<strong>Charlie (Interviewer)</strong>: ${last_message.content}`);
      }
      
      // Play the audio directly
      const audio = new Audio(audioUrl);
      audio.play();
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };

  const handleClearHistory = async () => {
    try {
      await axios.get(`${protocol}://${hostname}/${apiPath}/clear`);
      setMessages([]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Use useEffect to scroll to the bottom whenever messages change
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const handleFinishInterviewClick = async () => {
    const userConfirmed = window.confirm('Are you sure you have finished? If you finished, ' +
        'you cannot login again using this account');

    if (userConfirmed) {
      // Replace with your backend URL
      try {
        const status = await finishInterview(contextUsername)

        if (status === 200) {
          console.log('Information sent to the backend successfully');
          window.location.reload();
        } else {
          alert("Failed to finalising the interview. Please try again.")
        }
      } catch (error) {
        alert('Error, finalising interview failed:' + error);
      }
    }
  };


  return (
      <div className="MainApp">
        <h1>AI Interviewer</h1>
        <div className="chat-window" ref={chatWindowRef}> {/* Attach the reference */}
          {messages.map((message, index) => (
              <div key={index} className={`message ${message.role}`}>
                <p dangerouslySetInnerHTML={{__html: message.content}}></p> {/* Render text message only */}
              </div>
          ))}
        </div>
        <AudioRecorder onAudioSubmit={handleAudioSubmit}/>
        {/*<button onClick={handleClearHistory} className="clear-history-button">*/}
        {/*  <i className="fas fa-trash"></i>*/}
        {/*</button>*/}
        {loading && <div className="loading-icon"><i className="fas fa-spinner fa-spin"></i></div>}
        <br/>
        <button
            onClick={handleFinishInterviewClick}
            style={{fontSize: '16px', padding: '10px 20px', cursor: 'pointer'}}
        >
          I have finished
        </button>
      </div>
  );
}

export default MainApp;